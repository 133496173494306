<template>
  <!-- Start Content-->
  <div>
    <div class="alert alert-danger" v-if="deleteFlg">レポートを削除しました。</div>
    <div class="responsive-table-plugin btn-toolbar align-items-start justify-content-between">

      <div class="form-inline md-form form-sm toolbar-search">
        <input class="form-control mr-1 w-80" type="search" placeholder="Search" aria-label="Search" v-model="listStrage.query" @keyup.enter="search">
        <button type="button" class="btn btn-white" @click="search">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>
        <span class="text-secondary small w-100">※検索項目は、社名、氏名、物件名です。</span>
      </div>

      <div class="form-inline toolbar-display ml-auto">
        <div class="toolbar-display-count">
          <span class="mx-1">表示件数</span>
          <div>
            <select class="form-control mt-0" v-model="listStrage.limit" @change="changeLimit">
              <option>10</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option>500</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="responsive-table-plugin" v-else-if="!jsonErr">
      <div class="table-rep-plugin">
        <div class="table-responsive" data-pattern="priority-columns">
          <table class="table table-striped focus-on">
            <thead>
              <tr>
                <th></th>
                <th colspan="2"><div class="badge badge-success">貸主</div></th>
                <th colspan="2"><div class="badge badge-info">物件</div></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th><div class="d-flex align-items-center">No</div></th>
                <th><div class="th-inner sortable both" :class="sortClass('lender.company_name')" @click="sort('lender.company_name')">社名</div></th>
                <th><div class="th-inner sortable both" :class="sortClass('lender.name')" @click="sort('lender.name')">氏名</div></th>
                <th><div class="th-inner sortable both" :class="sortClass('property.name')" @click="sort('property.name')">物件名</div></th>
                <th><div class="th-inner sortable both" :class="sortClass('property.location')" @click="sort('property.location')">所在地</div></th>
                <th>誘致エリア</th>
                <th><div class="th-inner sortable both" :class="sortClass('author')" @click="sort('author')">担当者</div></th>
                <th><div class="th-inner sortable both" :class="sortClass('created_date_time')" @click="sort('created_date_time')">作成日時</div></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(obj, idNum) in listData"
                :key="idNum"
                :class="{ focused: isFocus === idNum }"
                @click="toggleFocus(idNum)"
              >
                <td>
                  <div class="table-data-container">
                    {{ (listStrage.paged - 1) * listStrage.limit + idNum + 1 }}
                  </div>
                </td>
                <td>
                  <div class="table-data-container">
                    {{ obj.summary.lender.company_name }}
                  </div>
                </td>
                <td>
                  <div class="table-data-container">
                    {{ obj.summary.lender.name }}
                  </div>
                </td>
                <td>
                  <div class="table-data-container">
                    {{ obj.summary.property.name }}
                  </div>
                </td>
                <td>
                  <div class="table-data-container">
                    {{ obj.summary.property.location }}
                  </div>
                </td>
                <td>
                  <div class="table-data-container">
                    <div v-for="row in obj.summary.areas_of_graph" :key="row">
                      <div v-if="row.omr_zone">
                        {{ row.omr_zone }}
                      </div>
                      <div v-else-if="row.prefecture">
                        <span>{{ row.prefecture }}</span>
                        <span v-if="row.city">{{ row.city }}</span>
                        <span v-if="row.town">{{ row.town }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="table-data-container">
                    {{ obj.summary.author }}
                  </div>
                </td>
                <td>
                  <div class="table-data-container">
                    {{ obj.created_date_time }}
                  </div>
                </td>
                <td class="table-focus-on-td">
                  <transition transition name="slide-btn">
                    <router-link
                      v-show="isFocus === idNum"
                      :to="{ name: viewParams.articleView, params: { dataId: obj[viewParams.articleParamId] } }"
                      class="btn btn-default"
                      >表示 <i class="mdi mdi-arrow-right-bold"></i
                    ></router-link>
                  </transition>
                  <transition transition name="slide-btn">
                    <router-link v-if="isFocus === idNum" :to="'/report/build_info?BuildingID='+obj['BuildingID']+'&industry='+(obj.summary.property.industry!=undefined?obj.summary.property.industry.join(','):'')+'&tolink='+viewParams.tolink" class="btn btn-default">物件表示 <i class="mdi mdi-arrow-right-bold"></i></router-link>
                  </transition>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="p-2">
            <code v-if="!jsonObj.total_count">表示データがありません。</code>
          </div>
        </div>
        <!-- end .table-responsive -->
      </div>
      <!-- end .table-rep-plugin-->

      <div class="d-flex justify-content-end mt-1" v-if="jsonObj.total_count">
        <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
        &nbsp;~&nbsp;
        <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj.count }}</span>
        &nbsp;/&nbsp;
        <span>{{ jsonObj.total_count }}</span>
      </div>
      <div class="d-flex justify-content-end mt-1" v-else>
        <span>0</span>
        &nbsp;~&nbsp;
        <span>0</span>
        &nbsp;/&nbsp;
        <span>0</span>
      </div>

      <ul class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1" v-if="jsonObj.total_count">
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('first')">«</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a>
        </li>
        <li
          class="footable-page mb-1"
          :class="{ active: num === listStrage.paged, hide: pageHide(num) }"
          v-for="num in maxPage"
          :key="num"
        >
          <a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('next')">›</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('last')">»</a>
        </li>
      </ul>
    </div>
    <!-- end .responsive-table-plugin-->
    <div v-else>
      <code> データ取得に失敗しました。 </code>
    </div>
  </div>
  <!-- end .container -->
</template>

<script>
import axios from "axios";

export default {
  name: "ReportListTable",
  props: {
    viewParams: {
      name: {
        type: String,
        default: ''
      },
      apiUrl: {
        type: String,
        default: "",
      },
      articleView: String,
      articleViewBuild: String,
      articleParamId: String
    },
  },
  components: {},
  data() {
    return {
      listStrage: {
        query: '',
        limit: 100,
        paged: 1,
        orderKey: 'created_date_time', //ソート キー
        orderBy: 'desc' //ソート 昇順/降順
      },
      maxPage: 0,
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object],
      },
      listData: [Object],
      isFocus: -1,
      jsonErr: false,
      isLoading: true,
      deleteFlg: 0 // レポート削除からの遷移の場合は1
    };
  },
  mounted() {
    if( this.$route.query.deleteFlg ) {
      this.deleteFlg = 1
    }
    // convert old storage
    if( localStorage.reportLimit ) {
      localStorage.setItem(this.viewParams.name + '_limit', localStorage.reportLimit)
      localStorage.removeItem('reportLimit')
    }
    // localstorageから表示件数を読み込み
    if( localStorage.getItem(this.viewParams.name + '_limit') ) {
      this.listStrage.limit = localStorage.getItem(this.viewParams.name + '_limit')
    }
    // sessionstorageから検索ワードを読み込み
    if( sessionStorage.getItem(this.viewParams.name + '_query') ) {
      this.listStrage.query = sessionStorage.getItem(this.viewParams.name + '_query')
    }
    // sessionstorageからページ数を読み込み
    if( sessionStorage.getItem(this.viewParams.name + '_paged') ) {
      this.listStrage.paged = Number(sessionStorage.getItem(this.viewParams.name + '_paged'))
    }
    // localstorageから並び順を読み込み
    if( localStorage.getItem(this.viewParams.name + '_orderkey') ) {
      this.listStrage.orderKey = localStorage.getItem(this.viewParams.name + '_orderkey')
    }
    if( localStorage.getItem(this.viewParams.name + '_orderby') ) {
      this.listStrage.orderBy = localStorage.getItem(this.viewParams.name + '_orderby')
    }

    axios
      .get(this.viewParams.apiUrl, {
        params: {
          offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
          limit: this.listStrage.limit, //表示件数
          q: this.listStrage.query, //キーワード
          orderby: this.listStrage.orderKey + ' ' + this.listStrage.orderBy //並び順
        },
      })
      .then(response => {
          this.jsonObj = response.data
          this.listData = this.jsonObj.data
          this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit)
          this.isLoading = false
        }
      )
      .catch(error => {
          console.log(error)
          this.jsonErr = true
          this.isLoading = false
      });
  },
  methods: {
    toggleFocus: function(idNum) {
      this.isFocus = idNum
    },
    search: function() {
      this.isLoading = true
      localStorage.setItem(this.viewParams.name + '_limit', this.listStrage.limit)
      sessionStorage.setItem(this.viewParams.name + '_query', this.listStrage.query)
      sessionStorage.setItem(this.viewParams.name + '_paged', 1)
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: 0, //開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query, //キーワード
            orderby: this.listStrage.orderKey + ' ' + this.listStrage.orderBy //並び順
          },
        })
        .then(response => (
            this.jsonObj = response.data,
            this.listData = this.jsonObj.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = 1,
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error)
            this.jsonErr = true
            this.isLoading = false
        });
    },
    changeLimit: function() {
      this.isLoading = true
      localStorage.setItem(this.viewParams.name + '_limit', this.listStrage.limit)
      sessionStorage.setItem(this.viewParams.name + '_paged', 1)
     
      this.setSessionKeyword()

      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: 0, //開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query, //キーワード
            orderby: this.listStrage.orderKey + ' ' + this.listStrage.orderBy //並び順
          },
        })
        .then(response => (
            this.jsonObj = response.data,
            this.listData = this.jsonObj.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = 1,
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error)
            this.jsonErr = true
            this.isLoading = false
        });
    },
    pagenateNum: function(num) {
      if(num !== this.listStrage.paged) {
        this.isLoading = true
     
        this.setSessionKeyword()
        
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: (num - 1) * this.listStrage.limit, //開始位置
              limit: this.listStrage.limit, //表示件数
              q: this.listStrage.query, //キーワード
              orderby: this.listStrage.orderKey + ' ' + this.listStrage.orderBy //並び順
            },
          })
          .then(response => (
              this.jsonObj = response.data,
              this.listData = this.jsonObj.data,
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
              this.listStrage.paged = Math.round(this.jsonObj.offset / this.listStrage.limit) + 1,
              sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
              this.isLoading = false
            )
          )
          .catch(error => {
              console.log(error)
              this.jsonErr = true
              this.isLoading = false
          });
      }
    },
    pagenateKey: function(key) {
      switch (key) {
        case "next":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true
            this.offset = this.listStrage.limit * this.listStrage.paged
          }
          break
        case "prev":
          if (this.listStrage.paged > 1) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2)
          }
          break
        case "first":
          if (this.listStrage.paged > 1) {
            this.isLoading = true
            this.offset = 0
          }
          break
        case "last":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.maxPage - 1)
          }
          break
        default:
          this.isLoading = false
          console.log("Non-existent key")
      }
     
      this.setSessionKeyword()

      if (this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              q: this.listStrage.query, //キーワード
              orderby: this.listStrage.orderKey + ' ' + this.listStrage.orderBy //並び順
            },
          })
          .then(response => (
              this.jsonObj = response.data,
              this.listData = this.jsonObj.data,
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
              this.listStrage.paged = Math.round(this.jsonObj.offset / this.listStrage.limit) + 1,
              sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
              this.isLoading = false
            )
          )
          .catch(error => {
              console.log(error)
              this.jsonErr = true
              this.isLoading = false
          });
      }
    },
    pageHide: function(num) {
      if (num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4) {
        return true
      }
    },
    sort: function(key) {
      this.isLoading = true
      if(this.listStrage.orderKey === key && this.listStrage.orderBy === 'asc') {
        // this.listStrage.orderKey = key
        this.listStrage.orderBy = 'desc'
        localStorage.setItem(this.viewParams.name + '_orderby', this.listStrage.orderBy)
      } else {
        this.listStrage.orderKey = key
        this.listStrage.orderBy = 'asc'
        localStorage.setItem(this.viewParams.name + '_orderkey', this.listStrage.orderKey)
        localStorage.setItem(this.viewParams.name + '_orderby', this.listStrage.orderBy)
      }
      
      this.setSessionKeyword()

      if (this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              // offset: this.offset, //開始位置
              offset: 0, //開始位置
              limit: this.listStrage.limit, //表示件数
              q: this.listStrage.query, //キーワード
              orderby: this.listStrage.orderKey + ' ' + this.listStrage.orderBy //並び順
            },
          })
          .then(response => {
              this.jsonObj = response.data
              this.listData = this.jsonObj.data
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit)
              this.listStrage.paged = 1
              sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged)
              this.isLoading = false
            }
          )
          .catch(error => {
              console.log(error)
              this.jsonErr = true
              this.isLoading = false
          });
      }
    },
    sortClass: function(key) {
      if(this.listStrage.orderKey === key) {
        return this.listStrage.orderBy
      } else {
        return false
      }
    },
    setSessionKeyword: function() {
      // storageに保存されている(検索が実行されている)キーワードをセット
      if( sessionStorage.getItem(this.viewParams.name + '_query') ) {
        this.listStrage.query = sessionStorage.getItem(this.viewParams.name + '_query')
      } else {
        this.listStrage.query = ''
      }
    }
  },
  watch: {
    isLoading: function() {
      if(!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(['loaded'], "*")
        })
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.table thead tr th {
  border-bottom: none;
}

.table thead tr:first-child th {
  background: #fff;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -0.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table thead th {
  border-bottom: none;
  padding: {
    bottom: 5px;
    top: 5px;
  }
  & .badge {
    display: block;
    line-height: 1.5;
    text-align: left;
  }
}

.table-data-container {
  max-height: 15rem;
  max-width: 20rem;
  overflow-y: auto;
}

.table.table-striped td {
  min-width: 8em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped th:first-child,
.table.table-striped td:first-child {
  min-width: auto;
  white-space: nowrap !important;
  width: auto;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: 0.35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}

// sort
.sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px!important;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }
  &.both {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
  }
  &.desc {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=");
  }
  &.asc {
   background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==");
  }
}
</style>
